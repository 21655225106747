<template>
  <v-container grid-list-xl class="mt-10 service-card-container">
    <v-layout row wrap>
      <v-flex xs12 md4>
        <v-img
          aspect-ratio=""
          height="200px"
          width="200"
          src="../../assets/img/logo-transparent.png"
          class="mx-auto"
        ></v-img>
        <v-img
          aspect-ratio
          height="700px"
          width="600px"
          src="../../assets/img/cable.jpg"
          class="white--text align-center text-center"
        >
          <div class="text-xs-h5 text-sm-h4 text-xl-h3">
            <p>{{ $t('company.partner') }}</p>
            <p>{{ $t('company.reliable') }}</p>
            <p>{{ $t('company.lifting') }}</p>
            <p>{{ $t('company.hoisting') }}</p>
          </div>
        </v-img>
        <div class="pt-2 text-center">{{ $t('country')}}</div>
        <div class="pt-2 text-center">
          <a href="tel:+32496644394">+32 496 644 394</a>
        </div>
      </v-flex>
      <v-flex xs12 md4>
        <v-img
          class="white--text align-end"
          height="300px"
          src="../../assets/img/PC080287.jpg"
        ></v-img>
        <div class="text-company-creation">
          {{ $t('company.story')}}
          <b>N-Sim</b> {{ $t('company.offer_1')}}
          <span class="text-red-colored"
            >{{ $t('company.offer_2')}}</span
          >
          <br />
          <br />{{ $t('company.assets')}}
        </div>
        <v-container>
          <v-row no-gutters>
            <v-col cols="4">
              <v-img
                aspect-ratio
                height="210px"
                width="180px"
                src="../../assets/img/bobines.jpg"
              ></v-img>
            </v-col>
            <v-col cols="4">
              <v-img
                aspect-ratio
                height="210px"
                width="180px"
                src="../../assets/img/chain.jpg"
              ></v-img>
            </v-col>
            <v-col cols="4">
              <v-img
                aspect-ratio
                height="210px"
                width="180px"
                src="../../assets/img/IMG_20180627_151310.jpg"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
      <v-flex xs12 md4>
        <h2 class="mt-10">{{$t('assets.title')}}</h2>
        <h4 class="mt-8">&#10004; {{$t('assets.followup.title')}}</h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('assets.followup.text.part1')}}
          <span class="text-red-colored">{{$t('assets.followup.text.part2')}}</span> {{$t('assets.followup.text.part3')}}
        </div>
        <h4>&#10004; {{$t('assets.training.title')}}</h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('assets.training.text.part1')}}
          <span class="text-red-colored">{{$t('assets.training.text.part2')}}</span>
          {{$t('assets.training.text.part3')}}
        </div>
        <h4>&#10004; {{$t('assets.expertise.title')}}</h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('assets.expertise.text.part1')}}
          <span class="text-red-colored"
            >{{$t('assets.expertise.text.part2')}}</span
          >
          {{$t('assets.expertise.text.part3')}}
        </div>
        <h4>&#10004; {{$t('assets.sector.title')}}</h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('assets.sector.text')}}
        </div>
        <h4>&#10004; {{$t('assets.warranty.title')}}</h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('assets.warranty.text')}}
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
h2 {
  color: red;
  font-size: 20px;
  font-family: cursive;
}

h4 {
  font-size: 18px;
}

.first-letter {
  font-size: 150%;
  color: red;
}

.hr-red-gradient {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

.paragraph {
  font-size: 18px;
  margin: 0px 90px 0px 20px;
}
.service-card-container {
  color: black;
  background-color: whitesmoke;
}

.text-company-creation {
  font-size: 18px;
  align-content: justify;
  margin: 30px 95px 30px 95px;
}

.text-red-colored {
  color: #ff0000;
}
</style>
