<template>
  <v-app>
    <v-container><LanguageSelector /></v-container>
    <v-carousel height="100%" show-arrows-on-hover>
      <v-carousel-item><Service /></v-carousel-item>
      <v-carousel-item><Story /></v-carousel-item>
    </v-carousel>
  </v-app>
</template>

<script>
import LanguageSelector from "./components/LanguageSelector";
import Service from "./components/Service";
import Story from "./components/Story";

export default {
  name: "app",

  components: {
    LanguageSelector,
    Service,
    Story,
  },

  data: () => ({
    //
  }),
};
</script>
