<template>
  <v-container grid-list-xl class="mt-10 story-card-container">
    <v-layout row wrap>
      <v-flex xs12 md4>
        <h2 class="mt-10">{{$t('contract.title')}} &laquo; {{$t('contract.type')}} &raquo;</h2>
        <h4 class="mt-8">
          &#10004;
          <span class="first-letter">{{$t('contract.listen.first')}}</span>{{$t('contract.listen.next')}}
        </h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('contract.listen.text')}}
        </div>

        <h4>
          &#10004;
          <span class="first-letter">{{$t('contract.guidance.first')}}</span>{{$t('contract.guidance.next')}}
        </h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('contract.guidance.text')}}
        </div>

        <h4>
          &#10004;
          <span class="first-letter">{{$t('contract.network.first')}}</span>{{$t('contract.network.next')}}
        </h4>
        <div class="pt-2 pb-12 paragraph">
          {{$t('contract.network.text.part1')}}
          <span class="text-red-colored">{{$t('contract.network.text.part2')}}</span> {{$t('contract.network.text.part3')}}
        </div>

        <hr class="mt-12 hr-red-gradient" />
        <div class="logo">
          <v-img
            aspect-ratio="1"
            height="100"
            width="100"
            src="../../assets/img/logo-transparent.png"
            class="mx-auto"
          ></v-img>
        </div>
        <div class="pt-8 pl-8 address">
          15, Quartier du Beaujolais <br />
          5590 Haversin <br />
          BELGIUM
        </div>
        <div class="pt-4 pl-8 phone">
          <a href="tel:+32496644394">+32 496 644 394</a>
        </div>
        <div class="pb-10 pl-8 mail">
          <a href="mailto:nicolas.simenya@nsim.be">nicolas.simenya@nsim.be</a>
        </div>
        <hr class="mx-auto hr-red-gradient" />
      </v-flex>
      <v-flex xs12 md4>
        <v-img
          aspect-ratio="1"
          class="mt-3"
          height="350px"
          width="600px"
          src="../../assets/img/boat.jpg"
        ></v-img>
        <h2 class="pt-10 pb-4 pl-14">{{$t('trust.title')}}</h2>
        <div class="pl-14 paragraph">
          {{$t('trust.text.part1')}}<br />
          {{$t('trust.text.part2')}}<br />
          {{$t('trust.text.part3')}}<br />
          {{$t('trust.text.part4')}} <br />
          {{$t('trust.text.part5')}}<br />
          {{$t('trust.text.part6')}}<br />
          {{$t('trust.text.part7')}}<br />
          {{$t('trust.text.part8')}}<br />
          {{$t('trust.text.part9')}}<br />
        </div>
        <div class="pt-6 pb-8 pl-14 paragraph-wish">
          {{$t('trust.text.part10')}}<br />
          {{$t('trust.text.part11')}}
        </div>
        <v-row no-gutters>
          <v-col>
            <v-img
              aspect-ratio
              height="240px"
              width="280px"
              src="../../assets/img/IMG_20180628_091811.jpg"
            ></v-img>
          </v-col>
          <v-col>
            <v-img
              aspect-ratio
              height="240px"
              width="280px"
              src="../../assets/img/grue.jpg"
            ></v-img>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 md4>
        <v-row>
          <v-col cols="auto">
            <v-img
              aspect-ratio
              height="350px"
              width="260px"
              src="../../assets/img/president.png"
            ></v-img>
          </v-col>
          <v-col cols="auto" class="text-center pl-0">
            <v-row class="flex-column ma-0 fill-height">
              <v-col>
                <v-img
                  aspect-ratio=""
                  height="150px"
                  width="120px"
                  src="../../assets/img/logo-transparent.png"
                  class="mx-auto"
                ></v-img>
                <div class="pt-8">
                  <b> Nicolas SIMENYA </b>
                </div>
                <div class="pt-4 address">15, Quartier du Beaujolais</div>
                <div class="pt-6 phone">
                  <a href="tel:+32496644394">+32 496 644 394</a>
                </div>
                <div class="pt-2 mail">
                  <a href="mailto:nicolas.simenya@nsim.be">
                    nicolas.simenya@nsim.be
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-8 pl-8 text-story">{{$t('bio.title')}}</v-col>
          <v-col class="pt-8 text-exp-duration">{{$t('bio.date')}}</v-col>
        </v-row>
        <div class="pl-6 pb-2 paragraph">
          {{$t('bio.text.part1')}}<br />
          {{$t('bio.text.part2')}}<br />
          {{$t('bio.text.part3')}}<br />
          {{$t('bio.text.part4')}}<br />
          {{$t('bio.text.part5')}}<br />
          {{$t('bio.text.part6')}}<br />
          {{$t('bio.text.part7')}}<br />
          {{$t('bio.text.part8')}}<br />
          {{$t('bio.text.part9')}}
        </div>
        <v-container>
          <v-row no-gutters>
            <v-col cols="6">
              <v-img
                aspect-ratio=""
                height="165px"
                width="270px"
                src="../../assets/img/grumier.jpg"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img
                aspect-ratio=""
                height="165px"
                width="270px"
                src="../../assets/img/PA272481.jpg"
              ></v-img>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6">
              <v-img
                aspect-ratio=""
                height="165px"
                width="270px"
                src="../../assets/img/PA282499.jpg"
              ></v-img>
            </v-col>
            <v-col cols="6">
              <v-img
                aspect-ratio=""
                height="165px"
                width="270px"
                src="../../assets/img/PB063873.jpg"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "story",
};
</script>

<style>
h2 {
  color: red;
  font-size: 20px;
  font-family: cursive;
}

h4 {
  font-size: 18px;
}

.paragraph {
  font-size: 18px;
}

.paragraph-wish {
  font-size: 18px;
  font-weight: bold;
}

.story-card-container {
  color: black;
  background-color: whitesmoke;
}
.text-exp-duration {
  font-size: 18px;
  font-weight: bold;
}
.text-story {
  color: red;
  font-size: 18px;
  font-weight: bold;
  font-family: cursive;
}
</style>
