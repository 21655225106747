<template>
  <v-overflow-btn
    class="my-2"
    v-model="selectedLanguage"
    :items="languages"
    item-text="text"
    item-value="lang"
    @change="languageChanged($event)"
  ></v-overflow-btn>
</template>

<script>
export default {
  data: () => ({
    selectedLanguage: localStorage.getItem("lang") || "fr",
    languages: [
      { lang: "fr", text: "Français" },
      { lang: "en", text: "English" },
    ],
    //
  }),
  methods: {
    languageChanged(event) {
      localStorage.setItem("lang", event);
      window.location.reload();
    },
  },
};
</script>

<style></style>
